import get from 'lodash/get';
import { query } from 'utils/graphql';
import { FACILITIES_LIST_PAGE } from 'shared/pageTypes';

// Actions
const LOAD_LIST = 'voyager/facilities/LOAD_LIST';
const LOAD_LIST_SUCCESS = 'voyager/facilities/LOAD_LIST_SUCCESS';
const LOAD_LIST_FAIL = 'voyager/facilities/LOAD_LIST_FAIL';
const LOAD = 'voyager/facilities/LOAD';
const LOAD_SUCCESS = 'voyager/facilities/LOAD_SUCCESS';
const LOAD_FAIL = 'voyager/facilities/LOAD_FAIL';
const LOAD_UNIT_GROUPS = 'voyager/facilities/LOAD_UNIT_GROUPS';
const LOAD_UNIT_GROUPS_SUCCESS = 'voyager/facilities/LOAD_UNIT_GROUPS_SUCCESS';
const LOAD_UNIT_GROUPS_FAIL = 'voyager/facilities/LOAD_UNIT_GROUPS_FAIL';
const LOAD_UNIT_SUMMARY = 'voyager/facilities/LOAD_UNIT_SUMMARY';
const LOAD_UNIT_SUMMARY_SUCCESS = 'voyager/facilities/LOAD_UNIT_SUMMARY_SUCCESS';
const LOAD_UNIT_SUMMARY_FAIL = 'voyager/facilities/LOAD_UNIT_SUMMARY_FAIL';
const LOAD_LOCATION_FILTERS_SUCCESS = 'voyager/facilities/LOAD_LOCATION_FILTERS_SUCCESS';
const LOAD_LOCATION_FILTERS_FAIL = 'voyager/facilities/LOAD_LOCATION_FILTERS_FAIL';
const UPDATE = 'voyager/facilities/UPDATE';
const SEARCH = 'voyager/facilities/SEARCH';
const SEARCH_SUCCESS = 'voyager/facilities/SEARCH_SUCCESS';
const SEARCH_FAIL = 'voyager/facilities/SEARCH_FAIL';
const LOCATION_SEARCH = 'voyager/facilities/LOCATION_SEARCH';
const LOCATION_SEARCH_SUCCESS = 'voyager/facilities/LOCATION_SEARCH_SUCCESS';
const LOCATION_SEARCH_FAIL = 'voyager/facilities/LOCATION_SEARCH_FAIL';
const FILTER = 'voyager/facilities/FILTER';
const FILTER_SUCCESS = 'voyager/facilities/FILTER_SUCCESS';
const FILTER_FAIL = 'voyager/facilities/FILTER_FAIL';
const UPDATE_FILTERS = 'voyager/pages/UPDATE_FILTERS';
export const UPDATE_SUCCESS = 'voyager/facilities/UPDATE_SUCCESS';
const UPDATE_FAIL = 'voyager/facilities/UPDATE_FAIL';
const LOAD_FACILITIES_PAGE = 'voyager/facilities/LOAD_FACILITIES_PAGE';
const LOAD_FACILITIES_PAGE_SUCCESS = 'voyager/facilities/LOAD_FACILITIES_PAGE_SUCCESS';
const LOAD_FACILITIES_PAGE_FAIL = 'voyager/facilities/LOAD_FACILITIES_PAGE_FAIL';
const LOAD_LIST_FOR_GROUPS = 'voyager/facilities/LOAD_LIST_FOR_GROUPS';
const LOAD_LIST_FOR_GROUPS_SUCCESS = 'voyager/facilities/LOAD_LIST_FOR_GROUPS_SUCCESS';
const LOAD_LIST_FOR_GROUPS_FAIL = 'voyager/facilities/LOAD_LIST_FOR_GROUPS_FAIL';

// Reducer
const initialState = {
  loading: false,
  loaded: false,
  searching: false,
  allFacilities: [],
  allFacilitiesPage: null,
  filteredIds: [],
  info: {},
  unitGroups: {},
  unitSummary: {},
  currentId: '',
  filters: {
    queryString: '',
    live: false,
    draft: false,
  },
  tenant: {},
  token: {},
  availableUnit: {},
  facilities: [],
  countries: [],
  states: [],
  allFacilitiesForGroups: [],
  loadedForGroups: false,
  loadingForGroups: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
    case LOAD_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOAD_LIST_SUCCESS:
      // allFacilities should always be an array (not a keyless object), even if it's empty
      return {
        ...state,
        loading: false,
        loaded: true,
        allFacilities: Object.keys(action.data.facilities.fetch).length ? action.data.facilities.fetch : [],
      };
    case LOAD_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case LOAD_FACILITIES_PAGE_SUCCESS:
      return {
        ...state,
        allFacilitiesPage: action.data.page,
      };
    case LOAD_FAIL:
      return {
        ...state,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        // currentId: action.id,
        allFacilities: action.data.facility
          ? state.allFacilities.concat([action.data.facility])
          : state.allFacilities,
        info: {
          ...state.info,
          [action.id]: {
            ...action.data.facility,
          },
        },
      };
    case LOAD_UNIT_GROUPS_SUCCESS:
      return {
        ...state,
        unitGroups: {
          ...state.unitGroups,
          [action.id]: action.data.facility.unitGroups,
        },
        unitSummary: {
          ...state.unitSummary,
          [action.id]: action.data.facility.unitSummary,
        },
      };
    case LOAD_UNIT_SUMMARY_SUCCESS:
      return {
        ...state,
        unitSummary: {
          ...state.unitSummary,
          [action.id]: action.data.facility.unitSummary,
        },
      };
    case LOAD_LOCATION_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        [action.locationType]: action.data.facilities[action.locationType],
      };
    case LOAD_LOCATION_FILTERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error.message,
      };
    case UPDATE:
      return {
        ...state,
        saving: true,
        error: null,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        saving: false,
        allFacilities: state.allFacilities.map((facility) => {
          if (facility.id === action.id) {
            return action.data.facility;
          }

          return facility;
        }),
        info: {
          ...state.info,
          [action.data.facility.id]: action.data.facility,
        },
      };
    case UPDATE_FAIL:
      return {
        ...state,
        saving: false,
        error: action.error.message,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        facilities: action.data.search.facilities,
      };
    case FILTER_SUCCESS:
      return {
        ...state,
        facilities: action.data.facilities.filter,
      };
    case SEARCH_FAIL:
    case FILTER_FAIL:
      return {
        ...state,
      };
    case UPDATE_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.filter,
        },
      };
    }
    case LOCATION_SEARCH:
      return {
        ...state,
        searching: true,
      };
    case LOCATION_SEARCH_SUCCESS:
      return {
        ...state,
        searching: false,
        filteredIds: action.data.locationSearch,
      };
    case LOCATION_SEARCH_FAIL:
      return {
        ...state,
        searching: false,
      };
    case LOAD_LIST_FOR_GROUPS:
      return {
        ...state,
        loadingForGroups: true,
      };
    case LOAD_LIST_FOR_GROUPS_SUCCESS:
      return {
        ...state,
        loadingForGroups: false,
        loadedForGroups: true,
        allFacilitiesForGroups: Object.keys(action.data.facilities.fetch).length
          ? action.data.facilities.fetch : [],
      };
    case LOAD_LIST_FOR_GROUPS_FAIL:
      return {
        ...state,
        loadingForGroups: false,
        loadedForGroups: false,
      };
    default:
      return state;
  }
}

// Selectors
export const getAllFacilities = state => state.facilities.allFacilities;

export const getFilteredFacilityIds = state => state.facilities.filteredIds;

export const getAllFacilitiesPage = state => state.facilities.allFacilitiesPage;

export const getFacilityById = (state, id) => {
  if (!id) {
    return undefined;
  }

  return state.facilities.allFacilities.find(facility => facility.id === id)
    || state.facilities.info[id];
};

export const getFacilityFieldReplacements = (state, id) => {
  const facility = getFacilityById(state, id);

  if (!facility) {
    return {};
  }

  return {
    ...(facility.name ? {
      name: {
        label: 'Facility Name',
        value: facility.name,
      },
    } : {}),
    ...(facility.address && facility.address.fullAddress ? {
      address: {
        label: 'Facility Address',
        value: facility.address.fullAddress,
      },
    } : {}),
    ...(facility.phone ? {
      phone: {
        label: 'Facility Phone',
        value: facility.phone,
      },
    } : {}),
    ...(facility.email ? {
      email: {
        label: 'Facility E-mail',
        value: facility.email,
      },
    } : {}),
  };
};

export const getSoftwareProviders = state => state.facilities.allFacilities
  .reduce((providers, facility) => {
    const { settings } = facility;

    if (!settings) {
      return providers;
    }

    if (providers.includes(settings.softwareProvider)) {
      return providers;
    }

    return [...providers, settings.softwareProvider];
  }, []);

export const facilitiesLoaded = state => state.facilities.loaded;
export const searchingFacilities = state => state.facilities.searching;

// Action Creators
export function loadList(enabled = false) {
  return {
    types: [LOAD_LIST, LOAD_LIST_SUCCESS, LOAD_LIST_FAIL],
    force: true,
    promise: query`
      query fetchFacilities(${{ enabled }}: Boolean) {
        facilities {
          fetch(enabled: $enabled) {
            id
            amenities {
              name
              enabled
            }
            facilityAmenities {
              name
              enabled
            }
            companyId
            brandName
            currencySymbol
            currency
            defaultImageDeleted
            directPhone
            discountPlans {
              ...DiscountPlanProps
            }
            email
            enabled
            expirationWindow
            facilityType
            googlePlaceId
            hasRentalCenter
            linkOutUrl
            name
            pagePaths {
              id
              locale
              path
              metadata {
                linkOut
              }
            }
            pageId
            pageStatus
            phone
            primaryLink
            primaryTarget
            primaryAlt
            primaryImage
            primaryImageMediumId
            rating
            rentalCenterUrl
            reservationWindow
            reviewsCount
            tenantPortalUrl
            timezone
            storeNumber
            useExpirationWindow
            useReservationWindow
            address {
              ...AddressProps
            }
            hours {
              office {
                ...HoursProps
              }
              access {
                ...HoursProps
              }
            }
            settings {
              ...SettingsProps
            }
            unitSummary {
              ...UnitSummaryProps
            }
          }
        }
      }

      ${FragmentDiscountPlanProps}
      ${FragmentAddressProps}
      ${FragmentHoursProps}
      ${FragmentSettingsProps}
      ${FragmentUnitSummaryProps}
    `,
  };
}

export function load(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id,
    promise: query`
      query GetFacility(${{ id }}: String!) {
        facility(id: $id) {
          ...FacilityProps
        }
      }

      ${FragmentFacilityProps}
    `,
  };
}

export function loadUnitGroups(id) {
  return {
    types: [LOAD_UNIT_GROUPS, LOAD_UNIT_GROUPS_SUCCESS, LOAD_UNIT_GROUPS_FAIL],
    id,
    promise: query`
      query GetUnitGroups(${{ id }}: String!) {
        facility(id: $id) {
          id
          companyId
          unitSummary {
            ...UnitSummaryProps
          }
          unitGroups {
            ...UnitGroupProps
          }
        }
      }

      ${FragmentUnitGroupProps}
      ${FragmentUnitSummaryProps}
    `,
  };
}

export function loadUnitSummary(id) {
  return {
    types: [LOAD_UNIT_SUMMARY, LOAD_UNIT_SUMMARY_SUCCESS, LOAD_UNIT_SUMMARY_FAIL],
    id,
    promise: query`
      query GetUnitSummary(${{ id }}: String!) {
        facility(id: $id) {
          id
          companyId
          unitSummary {
            ...UnitSummaryProps
          }
        }
      }

      ${FragmentUnitSummaryProps}
    `,
  };
}

export function loadAllFacilitiesPage() {
  return {
    types: [LOAD_FACILITIES_PAGE, LOAD_FACILITIES_PAGE_SUCCESS, LOAD_FACILITIES_PAGE_FAIL],
    force: true,
    promise: query`
      query GetPage {
        page(type: "${FACILITIES_LIST_PAGE}", live: true) {
          id
          locale
          path
          metadata {
            slug
          }
        }
      }
    `,
  };
}

export function update(id, data) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    id,
    force: true,
    promise: query`
      mutation UpdateFacility(${{ id }}: String!, ${{ data }}: FacilityInput!) {
        facility: updateFacility(id: $id, facility: $data) {
          ...FacilityProps
        }
      }

      ${FragmentFacilityProps}
    `,
  };
}

export function locationSearch({
  language,
  queryString,
  latitude,
  longitude,
  ip,
}) {
  return {
    types: [LOCATION_SEARCH, LOCATION_SEARCH_SUCCESS, LOCATION_SEARCH_FAIL],
    force: true,
    promise: query`
      query locationSearch(${{ language }}: String!, ${{ query: queryString }}: String,
        ${{ latitude }}: Float, ${{ longitude }}: Float, ${{ ip }}: String) {
        locationSearch(language: $language, query: $query,
          latitude: $latitude, longitude: $longitude, ip: $ip)
      }
    `,
  };
}

export function loadListForGroups() {
  return {
    types: [
      LOAD_LIST_FOR_GROUPS,
      LOAD_LIST_FOR_GROUPS_SUCCESS,
      LOAD_LIST_FOR_GROUPS_FAIL,
    ],
    force: true,
    promise: query`
      query fetchFacilities {
        facilities {
          fetch {
            ...FacilityProps
          }
        }
      }

      ${FragmentFacilityPropsForGroups}
    `,
  };
}

export function search(filters) {
  return {
    types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
    queryType: 'search',
    force: true,
    promise: query`
      query searchFacilities(${{ filters }}: SearchInput) {
        search {
          facilities(filters: $filters) {
            ...FacilityProps
          }
        }
      }

      ${FragmentFacilityPropsForGroups}
    `,
  };
}

export function searchFacilities() {
  return (dispatch, getState) => {
    const state = getState();

    return dispatch(search(state.facilities.filters));
  };
}

export function updateFilters(filter) {
  return {
    type: UPDATE_FILTERS,
    filter,
    force: true,
  };
}

export function loadCountries() {
  return {
    types: [LOAD, LOAD_LOCATION_FILTERS_SUCCESS, LOAD_LOCATION_FILTERS_FAIL],
    force: true,
    locationType: 'countries',
    promise: query`
      query fetchCountries {
        facilities {
          countries
        }
      }
    `,
  };
}

export function loadStates(countryCode) {
  return {
    types: [LOAD, LOAD_LOCATION_FILTERS_SUCCESS, LOAD_LOCATION_FILTERS_FAIL],
    force: true,
    locationType: 'states',
    promise: query`
      query fetchStates(${{ countryCode }}: String!) {
        facilities {
          states(countryCode: $countryCode)
        }
      }
    `,
  };
}

export function filterLocations(countryCode, stateCodes) {
  return {
    types: [FILTER, FILTER_SUCCESS, FILTER_FAIL],
    queryType: 'filter',
    force: true,
    promise: query`
      query filter(${{ countryCode }}: String!, ${{ stateCodes }}: [String]) {
        facilities {
          filter(countryCode: $countryCode, stateCodes: $stateCodes) {
            ...FacilityProps
          }
        }
      }

      ${FragmentFacilityPropsForGroups}
    `,
  };
}

// Global
function findFacilityIdFromState(state) {
  // Determines if we're on an admin page or not
  // Note: I hate this... god I hate this
  const pathname = get(state, 'routing.locationBeforeTransitions.pathname', '');
  const matches = pathname.match(/^\/admin\/page\/(edit|preview)\/([0-9a-z]+)/i);

  // First, look in the page itself for the ID
  const potentialId = get(
    state,
    matches ? 'pageBuilder.facilityId' : 'page.facilityId',
  );
  if (potentialId) {
    return potentialId;
  }

  const pageId = matches ? matches[1] : get(state, ['routes', 'map', pathname]);
  let facilityId;

  if (pageId) {
    state.pages.pages.some((page) => {
      if (page.id === pageId) {
        facilityId = page.facilityId; // eslint-disable-line prefer-destructuring
        return true;
      }

      return false;
    });
  }

  return facilityId;
}

export function findId(props = {}, state) {
  // Check the props for a facility ID, otherwise defer to the one set in state
  return props.facilityId || findFacilityIdFromState(state);
}

// Lets squirrel away some GraphQL fragments down here
const FragmentAddressProps = `
  fragment AddressProps on Address {
    address1
    address2
    city
    state
    postal
    country
    latitude
    longitude
    fullAddress
  }
`;

const FragmentHoursProps = `
  fragment HoursProps on HoursDef {
    note
    hours {
      day
      open
      close
    }
  }
`;

const FragmentSettingsProps = `
  fragment SettingsProps on FacilitySettings {
    enablePublicDescription
    disableReservations
    showAvailableUnitCount
    showAvailableUnitCountThreshold
    reservationDisclaimer
    reservationConfirmation
    enableMoveIns
    enableManagedRates
    enableUsePromoModifiedPricing
    hidePricingDisplayCall
    showUnavailableUnits
    callActionThreshold
    businessNameOverride
    businessAddressDescription
    tagline
    voyagerOnly
    softwareProvider
    leaseUrl
    twentyFourHourPassport
    showSalesBanner
    useWeeklyRates
  }
`;

const FragmentUnitGroupProps = `
  fragment UnitGroupProps on UnitGroup {
    id
    category
    categoryName
    name
    type
    area
    length
    width
    height
    size
    dueAtMoveIn
    dueAtMoveInWithoutFees
    dueMonthly
    availableUnitsCount
    totalUnitsCount
    price
    priceWeekly
    isWeekly
    popular
    leadOnly
    discountedPrice
    discountedPriceWeekly
    promoPrice
    promoPriceWeekly
    standardRate
    standardRateWeekly
    amenities {
      name
      token
    }
    promoId
    moveInUrl
    reservationUrl
    sort
    discountPlans {
      id
      availabilityText
      requirementsText
      availableForAllFacilities
      autoApply
      moveInOnly
      description
      publicDescription
      discountPlanDiscounts {
        id
        discountType
        monthNumber
        amount
      }
      hideFromWebsite
      name
      priority
      promotionType
      turnedOn
      startDate
      endDate
    }
    pricingTypeId
    availablePricingTypes {
      pricingTypeId
      marketRate
      marketRateWeekly
      walkInRate
      walkInRateWeekly
      firstAvailableUnitName
      floor
      door1Width
      door1Height
      door2Width
      door2Height
      door3Width
      door3Height
      door4Width
      door4Height
      proximityToAccessPoint
      proximityInDoors
    }
    payLaterOnly
    firstAvailableUnitName
    floor
    door1Width
    door1Height
    door2Width
    door2Height
    door3Width
    door3Height
    door4Width
    door4Height
    proximityToAccessPoint
    proximityInDoors
  }
`;

const FragmentDiscountPlanProps = `
  fragment DiscountPlanProps on DiscountPlan {
    id
    availabilityText
    requirementsText
    availableForAllFacilities
    autoApply
    description
    publicDescription
    discountPlanDiscounts {
      id
      discountType
      monthNumber
      amount
    }
    hideFromWebsite
    name
    priority
    promotionType
    turnedOn
    startDate
    endDate
  }
`;

const FragmentUnitSummaryProps = `
  fragment UnitSummaryProps on UnitSummary {
    id
    name
    minPrice
    minPriceWeekly
    maxPrice
    maxPriceWeekly
    minArea
    minSize
    availableUnitsCount
    totalUnitsCount
    siteUnitCategoryId
  }
`;

const FragmentFacilityProps = `
  fragment FacilityProps on Facility {
    id
    companyId
    brandName
    currency
    currencySymbol
    defaultImageDeleted
    directPhone
    email
    enabled
    expirationWindow
    facilityType
    googlePlaceId
    hasRentalCenter
    linkOutUrl
    name
    pageId
    pageStatus
    pagePaths {
      id
      locale
      path
      metadata {
        linkOut
      }
    }
    phone
    primaryLink
    primaryTarget
    primaryAlt
    primaryImage
    rating
    rentalCenterUrl
    reservationFeeRequired
    storeNumber
    reservationWindow
    tenantPortalUrl
    timezone
    useExpirationWindow
    useReservationWindow
    address {
      ...AddressProps
    }
    amenities {
      enabled
      name
    }
    facilityAmenities {
      name
      enabled
    }
    hours {
      office {
        ...HoursProps
      }
      access {
        ...HoursProps
      }
    }
    reviews {
      id
      body
      name
      rating
      timestamp
      reply {
        body
        timestamp
      }
    }
    settings {
      ...SettingsProps
    }
    unitGroups {
      ...UnitGroupProps
    }
    unitSummary {
      ...UnitSummaryProps
    }
    discountPlans {
      id
      availabilityText
      requirementsText
      availableForAllFacilities
      autoApply
      description
      publicDescription
      discountPlanDiscounts {
        id
        discountType
        monthNumber
        amount
      }
      hideFromWebsite
      name
      priority
      promotionType
      turnedOn
      startDate
      endDate
    }
  }

  ${FragmentAddressProps}
  ${FragmentHoursProps}
  ${FragmentSettingsProps}
  ${FragmentUnitGroupProps}
  ${FragmentUnitSummaryProps}
`;

const FragmentFacilityPropsForGroups = `
  fragment FacilityProps on Facility {
    id
    companyId
    enabled
    facilityType
    name
    pageStatus
    storeNumber
    address {
      ...AddressProps
    }
  }

  ${FragmentAddressProps}
`;
