import { query } from 'utils/graphql';
import get from 'lodash/get';

// Actions
const CREATE = 'voyager/lead/CREATE';
const CREATE_SUCCESS = 'voyager/lead/CREATE_SUCCESS';
const CREATE_FAIL = 'voyager/lead/CREATE_FAIL';
const CREATE_SOURCE = 'voyager/lead/CREATE_SOURCE';
const CREATE_SOURCE_SUCCESS = 'voyager/lead/CREATE_SOURCE_SUCCESS';
const CREATE_SOURCE_FAIL = 'voyager/lead/CREATE_SOURCE_FAIL';
const GET_LEAD = 'voyager/lead/GET_LEAD';
const GET_LEAD_SUCCESS = 'voyager/lead/GET_LEAD_SUCCESS';
const GET_LEAD_FAIL = 'voyager/lead/GET_LEAD_FAIL';
const RESET = 'voyager/lead/RESET';
const RESERVATION = 'voyager/lead/RESERVATION';
const RESERVATION_SUCCESS = 'voyager/lead/RESERVATION_SUCCESS';
const RESERVATION_FAIL = 'voyager/lead/RESERVATION_FAIL';

// Reducer
const initialState = {
  creating: false,
  created: false,
  errorMessage: '',
  creatingSource: false,
  createdSource: false,
  sourceErrorMessage: '',
  leadLoading: false,
  leadData: undefined,
  leadError: undefined,
  reserving: false,
  reserved: false,
  leadUnit: undefined,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE:
      return {
        ...state,
        creating: true,
        created: false,
        errorMessage: '',
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        created: true,
      };
    case CREATE_FAIL:
      return {
        ...state,
        creating: false,
        created: false,
        errorMessage: action.error.response.body.errors[0].message,
      };
    case CREATE_SOURCE:
      return {
        ...state,
        creatingSource: true,
        createdSource: false,
        sourceErrorMessage: '',
      };
    case CREATE_SOURCE_SUCCESS:
      return {
        ...state,
        creatingSource: false,
        createdSource: true,
      };
    case CREATE_SOURCE_FAIL:
      return {
        ...state,
        creatingSource: false,
        createdSource: false,
        sourceErrorMessage: action.error.response.body.errors[0].message,
      };
    case GET_LEAD:
      return {
        ...state,
        leadLoading: true,
        leadUnit: undefined,
        leadData: undefined,
        leadError: undefined,
      };
    case GET_LEAD_SUCCESS:
      return {
        ...state,
        leadLoading: false,
        leadData: action.data.lead,
        leadUnit: action.data.lead.unit,
      };
    case GET_LEAD_FAIL:
      return {
        ...state,
        leadLoading: false,
        leadError: get(action, 'error[0].message'),
      };
    case RESERVATION:
      return {
        ...state,
        reserving: true,
        reserved: false,
        errorMessage: '',
      };
    case RESERVATION_SUCCESS:
      return {
        ...state,
        reserving: false,
        reserved: true,
      };
    case RESERVATION_FAIL:
      return {
        ...state,
        reserving: false,
        reserved: false,
        errorMessage: action.error.response.body.errors[0].message,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function create({
  leadId,
  facilityId,
  unitId,
  unitGroupId,
  source,
  tenantId,
  tenant,
  isGated,
  curious,
  notes,
  discountPlanId,
  autoApplyDiscountPlanIds,
  moveInDate,
  googleSessionId,
  googleClientId,
  utmSource,
  utmMedium,
  utmCampaign,
  metaAdId,
  metaCreativeId,
  metaAdSetId,
}) {
  return {
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    force: true,
    /* eslint-disable max-len */
    promise: query`
      mutation CreatePopUpLead(
        ${{ leadId }}: String
        ${{ facilityId }}: String!
        ${{ unitId }}: String
        ${{ unitGroupId }}: String
        ${{ source }}: String!
        ${{ tenantId}}: String
        ${{ tenant }}: LeadTenantInput
        ${{ isGated }}: Boolean
        ${{ curious }}: Boolean
        ${{ notes }}: String
        ${{ discountPlanId }}: String
        ${{ autoApplyDiscountPlanIds }}: [String]
        ${{ moveInDate }}: String
        ${{ googleSessionId }}: String
        ${{ googleClientId }}: String
        ${{ utmSource }}: String
        ${{ utmMedium }}: String
        ${{ utmCampaign }}: String
        ${{ metaAdId }}: String
        ${{ metaCreativeId }}: String
        ${{ metaAdSetId }}: String
      ) {
        lead: createPopUpLead(
          leadId: $leadId
          facilityId: $facilityId
          unitId: $unitId
          unitGroupId: $unitGroupId
          source: $source
          tenantId: $tenantId
          tenant: $tenant
          isGated: $isGated
          curious: $curious
          notes: $notes
          discountPlanId: $discountPlanId
          autoApplyDiscountPlanIds: $autoApplyDiscountPlanIds
          moveInDate: $moveInDate
          googleSessionId: $googleSessionId
          googleClientId: $googleClientId
          utmSource: $utmSource
          utmMedium: $utmMedium
          utmCampaign: $utmCampaign
          metaAdId: $metaAdId
          metaCreativeId: $metaCreativeId
          metaAdSetId: $metaAdSetId
        ) {
          leadId
          error
          desiredMoveInDate
          status
          tenant {
            id
            firstName
            lastName
            email
            phone
            phoneNumberId
            smsOptIn
            addressId
            address1
            address2
            city
            country
            state
            postal
          }
          discountPlan {
            id
            name
            description
          }
          unit {
            id
            name
            price
            tier_id
          }
        }
      }
    `,
  };
}
/* eslint-enable max-len */

export function createLeadSource(name) {
  return {
    types: [CREATE_SOURCE, CREATE_SOURCE_SUCCESS, CREATE_SOURCE_FAIL],
    promise: query`
      mutation CreateCustomLeadSource(
        ${{ name }}: String!
      ) {
        leadSource: createCustomLeadSource(name: $name) {
          name
        }
      }
    `,
  };
}

export function getLeadData({ facilityId, leadId }) {
  return {
    types: [GET_LEAD, GET_LEAD_SUCCESS, GET_LEAD_FAIL],
    promise: query`
      query GetLead(
        ${{ facilityId }}: String!
        ${{ leadId }}: String!
      ) {
        lead(facilityId: $facilityId, leadId: $leadId) {
          success
          error
          leadId
          desiredMoveInDate
          closed
          expired
          movedIn
          status
          tenant {
            id
            firstName
            lastName
            first_name
            last_name
            email
            phone
            phoneNumberId
            smsOptIn
            addressId
            address1
            address2
            city
            country
            state
            postal
            isMilitary
            mailing_address {
              addressId
              address1
              address2
              city
              country
              state
              postal
            }
            contacts {
              first_name
              last_name
              description
              primary
              phone_numbers {
                formatted_number
                primary
              }
            }
          }
          unit {
            id
            name
            width
            length
            height
            unitGroupId
            price
            unit_type {
              name
            }
            unit_amenities {
              name
              short_code
            }
            custom_fields {
              name
              value
            }
          }
        }
      }
    `,
  };
}

export function convertLeadToReservation({
  facilityId,
  leadId,
  unitId,
  moveInDate,
  originalMoveInDate,
  discountPlanIds,
}) {
  return {
    types: [RESERVATION, RESERVATION_SUCCESS, RESERVATION_FAIL],
    force: true,
    /* eslint-disable max-len */
    promise: query`
      mutation ConvertLeadToReservation(
        ${{ facilityId }}: String!
        ${{ leadId }}: String!
        ${{ unitId }}: String!
        ${{ moveInDate }}: String!
        ${{ originalMoveInDate }}: String
        ${{ discountPlanIds }}: [String]
      ) {
        lead: convertLeadToReservation(
          facilityId: $facilityId
          leadId: $leadId
          unitId: $unitId
          moveInDate: $moveInDate
          originalMoveInDate: $originalMoveInDate
          discountPlanIds: $discountPlanIds
        ) {
          success
          error
          leadId
          desiredMoveInDate
          tenant {
            id
            firstName
            lastName
            email
            phone
            phoneNumberId
            smsOptIn
            addressId
            address1
            address2
            city
            country
            state
            postal
          }
          discountPlan {
            id
            name
            description
          }
        }
      }
    `,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}

// Selectors
export const getLead = state => state.lead;
