import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  reviewCost,
  getFirstAvailableUnitFromUnitGroup,
  storableTenantMovein as processMoveIn,
  checkPromoCode,
  removeAppliedPromotion,
} from '../../../../../src/web/store/modules/movein';
import { create, getLeadData, convertLeadToReservation } from '../../../../../src/web/store/modules/lead';
import { update as updateTenant, removeContact, updateContact, addContact } from '../../../../../src/web/store/modules/tenant';
import compose from '../../../../../src/web/utils/react/compose';
import StorageMartCheckout from './StorageMartCheckout';

const component = compose(
  connect(
    (state) => {
      const { movein, lead } = state;
      const {
        moveInDate,
        pricing,
        saving,
        availableUnit,
        findingAvailableUnit,
        availableUnitResponse,
        checkingPromoCode,
        checkPromoCodeData,
        appliedPromotion,
      } = movein;

      const {
        creating: createLeadCreating,
        created: createLeadCreated,
        errorMessage: createLeadErrorMessage,
        leadLoading,
        leadData,
        leadUnit,
        leadError,
      } = lead;

      const availableDates = [];
      if (pricing) {
        pricing.forEach(({ moveInDate: pricingMoveInDate }) => {
          availableDates.push(pricingMoveInDate);
        });
      }

      const selectedUnit = leadData?.status === 'reserved'
        ? leadUnit
        : get(availableUnitResponse, 'units[0]');

      let receipt;

      if (moveInDate && pricing) {
        const pricingForDate = pricing.find(option => option.moveInDate === moveInDate);

        receipt = pricingForDate ? {
          invoiceableItems: pricingForDate.invoiceableFees,
          creditAmount: pricingForDate.proratedCredit,
          feeAmount: pricingForDate.invoiceableFees.length
            ? pricingForDate.invoiceableFees[0].amount
            : 0,
          rentAmount: pricingForDate.dueFirstMonth,
          rentMonth: moveInDate,
          nextDueDate: pricingForDate.nextDueDate,
          totalAmount: pricingForDate.dueAtMoveIn,
          taxAmount: pricingForDate.taxAmount,
          discountPlans: pricingForDate.discountPlans,
        } : undefined;
      }

      return {
        unitUnavailableError: availableUnit && availableUnit.errorMessage,
        availableDates,
        pricing,
        receipt,
        receiptLoading: saving,
        findingAvailableUnit,
        availableUnitResponse,
        availableUnit: selectedUnit,
        availableUnits: availableUnitResponse?.units,
        createLeadCreating,
        createLeadCreated,
        createLeadErrorMessage,
        leadLoading,
        leadData,
        leadError,
        checkingPromoCode,
        checkPromoCodeData,
        appliedPromotion,
      };
    },
    {
      completeReviewCost: reviewCost,
      getFirstAvailableUnitFromUnitGroup,
      submitLead: create,
      updateTenant,
      processMoveIn,
      getLeadData,
      removeContact,
      updateContact,
      addContact,
      convertLeadToReservation,
      checkPromoCode,
      removeAppliedPromotion,
    },
  ),
)(StorageMartCheckout);

export default component;
