import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { object, string, date as yupDate } from 'yup';
import { Alert } from 'react-bootstrap';
import get from 'lodash/get';
import { Button } from '@canaveral/components';
import { useIntl } from 'react-intl';
import styles from './styles.scss';
import withForm from '../../../../../../admin/hocs/withForm';
import Translation from '../../../../../components/stateless/Translation';
import renderAmenities from '../../../../../components/stateful/UnitsTable/themes/StorageMart/renderAmenities';
import {
  EASIER_PRICING_TYPE_ID,
  EASIEST_PRICING_TYPE_ID,
  SECOND_FULL_MONTH_FREE_ID,
  sizeMessageProps,
} from '../constants';
import CheckoutSubmitButton from '../CheckoutSubmitButton';
import CheckoutFormFieldText from '../CheckoutFormFieldText';
import CheckoutFormFieldDatePicker from '../CheckoutFormFieldDatePicker';
import {
  TRACK_CLICK_CHECKOUT_STEP_TWO_SUBMIT,
  TRACK_CLICK_CHECKOUT_UPGRADE_EASY,
  TRACK_CHECKOUT_STEP,
  TRACK_ADD_TO_CART_UNIT_GROUP,
  TRACK_REMOVE_FROM_CART_UNIT_GROUP,
} from '../../../../../../src/web/utils/dataLayer';
import UnitUpgradeOptions from './unit-upgrade-options';

const currentDate = new Date();
currentDate.setHours(0, 0, 0, 0);

const propTypes = {
  facility: PropTypes.shape({
    settings: PropTypes.shape({
      useWeeklyRates: PropTypes.bool.isRequired,
    }).isRequired,
  }),
  formError: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isReservation: PropTypes.bool.isRequired,
  maxStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  lowestTier: PropTypes.shapeOf(),
  availableUpgrades: PropTypes.arrayOf(),
  availableUnits: PropTypes.arrayOf(),
  onReviewCost: PropTypes.func.isRequired,
  appliedDiscountPlanId: PropTypes.string,
  continuingExistingLead: PropTypes.bool.isRequired,
  availableUnit: PropTypes.shape().isRequired,
};

const defaultProps = {
  facility: undefined,
  lowestTier: undefined,
  availableUpgrades: [],
  appliedDiscountPlanId: undefined,
  availableUnits: [],
};

const validationSchema = () => (
  object().shape({
    moveInDate: yupDate()
      .min(currentDate, 'widgets.checkout.moveInDate.minimum')
      .required('widgets.checkout.moveInDate.required'),
    tierId: string(),
    unitId: string()
      .required('widgets.checkout.unitId.required'),
  })
);

const initialValues = {
  moveInDate: '',
  pricingTypeId: '',
};

const UnitInformationForm = ({
  handleSubmit,
  submitting,
  formError,
  facility,
  setFieldValue,
  values,
  maxStep,
  currentStep,
  lowestTier,
  availableUpgrades,
  formData,
  onReviewCost,
  appliedDiscountPlanId,
  continuingExistingLead,
  availableUnits,
  availableUnit,
}) => {
  if (!facility) {
    return null;
  }
  const intl = useIntl();
  const [hasTrackedDataLayer, setHasTrackedDataLayer] = useState(false);
  const today = new Date();

  const {
    currency,
    currencyLocale,
    unitGroup,
    settings,
    settings: { useWeeklyRates } = {},
  } = facility;

  const {
    allowFutureMoveInsLimit,
  } = settings;

  const {
    price,
    discountedPrice,
    discountedPriceWeekly,
    priceWeekly,
  } = unitGroup;

  const persistedTierId = formData?.unit.tier_id;
  const [showUpgradeOptions, setShowUpgradeOptions] = useState(false);

  useEffect(() => {
    if (!hasTrackedDataLayer && facility) {
      setHasTrackedDataLayer(true);
      TRACK_CHECKOUT_STEP(get(facility, 'unitGroup'), facility, 'new_booking_flow_step2', 'storage unit');
    }
  }, [facility, hasTrackedDataLayer]);

  const weeklyRate = (discountedPriceWeekly || priceWeekly);
  const monthlyRate = (discountedPrice || price);
  const updateRate = useWeeklyRates ? weeklyRate : monthlyRate;
  const unitPrice = formData?.status === 'reserved' ? formData.unit.price : updateRate;

  const easierPricingType = availableUpgrades.find(availableUpgrade => (
    availableUpgrade.tier.id === EASIER_PRICING_TYPE_ID
  ));
  const easiestPricingType = availableUpgrades.find(availableUpgrade => (
    availableUpgrade.tier.id === EASIEST_PRICING_TYPE_ID
  ));

  const onCheckInDateChange = (date) => {
    onReviewCost(date, values.unitId);
  };

  const setPricingType = (upgradeUnit) => {
    setFieldValue('unitId', upgradeUnit.id);
    setFieldValue('tierId', upgradeUnit.tier.id);
    if (values.moveInDate) {
      onReviewCost(values.moveInDate, upgradeUnit.id);
    }
  };

  useEffect(() => {
    if ((easierPricingType || easiestPricingType) && formData?.status !== 'reserved' && availableUnits.length > 1) {
      if (maxStep === currentStep && !continuingExistingLead) {
        setPricingType((easierPricingType || easiestPricingType));
      }

      if (values.moveInDate) {
        setShowUpgradeOptions(true);
      }
    }
  }, [values.moveInDate, easierPricingType, easiestPricingType, setShowUpgradeOptions]);

  const firstMonthFree = appliedDiscountPlanId === SECOND_FULL_MONTH_FREE_ID;

  const setEasierPricingType = () => {
    if (
      (persistedTierId === EASIER_PRICING_TYPE_ID || easierPricingType)
      && values.tierId !== EASIER_PRICING_TYPE_ID
    ) {
      setPricingType(easierPricingType);
    }
  };

  const setEasiestPricingType = () => {
    if (
      (persistedTierId === EASIEST_PRICING_TYPE_ID || easiestPricingType)
      && values.tierId !== EASIEST_PRICING_TYPE_ID
    ) {
      setPricingType(easiestPricingType);
    }
  };

  const setDefaultPricingType = () => {
    if (values.tierId !== lowestTier.tier.id) {
      setPricingType(lowestTier);
    }
  };

  const fireDataLayerEvents = () => {
    if (values.tierId !== persistedTierId) {
      const defaultUnitGroup = { ...get(facility, 'unitGroup') };
      const selectedPricingType = availableUpgrades.find(availableUpgrade => (
        availableUpgrade.tier.id === values.tierId
      ));
      TRACK_REMOVE_FROM_CART_UNIT_GROUP(defaultUnitGroup, facility, intl);
      defaultUnitGroup.discountedPrice = selectedPricingType.price;
      defaultUnitGroup.unit_upgrade = 'yes';
      TRACK_ADD_TO_CART_UNIT_GROUP(defaultUnitGroup, facility, intl);
    }
  };

  return (
    <div>
      {
        formError && (
          <Alert bsStyle="danger">
            <Translation
              id="widgets.checkout.formError"
              defaultMessage="An error was encountered when submitting the form. Please try again."
            />
          </Alert>
        )
      }
      <div className="flex gap-2 place-content-between mb-2">
        <div>
          <h3>
            {`${get(availableUnit, 'width')}'x${get(availableUnit, 'length')}'`}
            {' '}
            <Translation
              {...sizeMessageProps[get(facility, 'unitGroup.categoryName').toLowerCase()]}
            />
          </h3>
          <div className={styles.amenityContainer}>
            {renderAmenities(get(facility, 'unitGroup.amenities'), styles.amenity, 'text-sm', styles.tooltipIcon)}
          </div>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="mt-6"
      >
        <CheckoutFormFieldDatePicker
          required
          name="moveInDate"
          minDate={today}
          maxDate={new Date().setDate(today.getDate() + allowFutureMoveInsLimit)}
          onChange={onCheckInDateChange}
          label={{
            defaultMessage: 'Check-in date',
            id: 'widgets.checkout.moveInDate',
          }}
        />
        {showUpgradeOptions
          && (
            <UnitUpgradeOptions
              easierPricingType={easierPricingType}
              setEasierPricingType={setEasierPricingType}
              easiestPricingType={easiestPricingType}
              setEasiestPricingType={setEasiestPricingType}
              currency={currency}
              currencyLocale={currencyLocale}
              useWeeklyRates={useWeeklyRates}
              unitPrice={unitPrice}
              unitGroup={unitGroup}
              tierId={values.tierId}
              firstMonthFree={firstMonthFree}
            />
          )}
        <CheckoutFormFieldText
          type="hidden"
          name="unitId"
        />
        <CheckoutFormFieldText
          type="hidden"
          name="tierId"
        />
        <CheckoutSubmitButton
          submitLabel={{
            id: 'widgets.checkout.saveButton.submit',
            defaultMessage: 'Save and Continue',
          }}
          submitting={submitting}
          submittingLabel={{
            id: 'widgets.checkout.saveButton.submitting',
            defaultMessage: 'Saving...',
          }}
          onClick={fireDataLayerEvents}
          {...TRACK_CLICK_CHECKOUT_STEP_TWO_SUBMIT}
        />
        {showUpgradeOptions
          && (
            <Button
              context="link"
              onClick={setDefaultPricingType}
              className="mt-4 float-right"
              disabled={submitting}
              type="submit"
              {...TRACK_CLICK_CHECKOUT_UPGRADE_EASY}
            >
              <Translation
                id="widgets.checkout.noThanksButton"
                defaultMessage="No Thanks"
              />
            </Button>
          )
        }
      </form>
    </div>
  );
};

UnitInformationForm.propTypes = propTypes;
UnitInformationForm.defaultProps = defaultProps;

export default withForm({
  mapPropsToValues: (props) => {
    const formattedInitialValues = initialValues;

    if (props.formData) {
      const parsedDesiredMoveInDate = props.formData.desiredMoveInDate ? new Date(`${props.formData.desiredMoveInDate}T00:00:00.000`) : undefined;
      if (parsedDesiredMoveInDate
        && (props.maxStep !== props.currentStep || props.continuingExistingLead)
        && parsedDesiredMoveInDate >= currentDate
      ) {
        formattedInitialValues.moveInDate = parsedDesiredMoveInDate;
      }

      formattedInitialValues.tierId = props.formData.unit.tier_id;
      formattedInitialValues.unitId = props.formData.unit.id;
    }

    return formattedInitialValues;
  },
  validationSchema,
  handleSubmit: (formPayload, formikBag) => (
    formikBag.props.onSubmit(
      formPayload,
      formikBag.props.currentStep,
      formikBag.props.nextStep,
      formikBag.props.scrollToRef,
    )
  ),
})(UnitInformationForm);
