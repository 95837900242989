import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, Alert } from 'react-bootstrap';
import get from 'lodash/get';
import omit from 'lodash/omit';
import find from 'lodash/find';
import map from 'lodash/map';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import head from 'lodash/head';
import padStart from 'lodash/padStart';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import gql from 'graphql-tag';
import ContactInformation from './ContactInformation';
import UnitInformation from './UnitInformation';
import AccountDetails from './AccountDetails';
import Payment from './Payment';
import OrderSummary, { MobileOrderSummary } from './OrderSummary';
import CheckoutProgress from './CheckoutProgress';
import CurrentStepContext, { CHECKOUT_STEPS } from './CurrentStepContext';
import { getQueryParams } from '../../../../../modules/search';
import ApolloClientConsumer from '../../../../../modules/react/components/stateful/ApolloClientConsumer';
import styles from './styles.scss';
import intlPath from '../../../../../src/web/utils/intlPath';
import { TRACK_BEGIN_CHECKOUT, TRACK_VIEW_CART, trackDataLayerValue } from '../../../../../src/web/utils/dataLayer';
import {
  errorTokens,
  DATE_FORMAT_LOCALE,
  DOOR_QUANTITY_CUSTOM_FIELD,
} from './constants';
import LoadingSkeleton from './LoadingSkeleton';
import useLazyQuery from '../../../../../modules/react/hooks/useLazyQuery';
import Translation from '../../../../components/stateless/Translation';
import { getBrandCode } from '../../../../../constants/smBrandNames';
import adCampaignValues from '../../../../../src/web/utils/adCampaignValues';
import SMFacilityLinkButton from '../../../components/stateless/SMFacilityLinkButton';

const typeNameList = [
  '__typename',
  'tenant.__typename',
  'tenant.address.__typename',
  'tenant.unitAddress.__typename',
  'unit.__typename',
  'paymentMethod.__typename',
  'paymentMethod.billingAddress.__typename',
];

export const GET_FACILITY_WITH_UNIT_GROUP = gql`
  query GetSMRentalSummaryData($id: ID!, $unitGroupId: ID!) {
    facility(id: $id) {
      id
      currency
      currencyLocale
      currencySymbol
      brandName
      storeNumber
      useExpirationWindow
      expirationWindow
      primaryImageMediumId
      address {
        streetAddress
        areaAddress
        country
      }
      settings {
        softwareProvider
        allowFutureMoveInsLimit
        useWeeklyRates
      }
      locks {
        id
        description
      }
      unitGroup(id: $unitGroupId) {
        id
        width
        length
        height
        amenities {
          id
          name
          token
        }
        availableUnitsCount
        discountedPrice
        price
        pricedWeekly
        discountedPriceWeekly
        priceWeekly
        area
        size
        category
        categoryName
        discountPlans {
          id
          name
          priority
        }
        availablePricingTypes {
          pricingTypeId
          marketRate
          marketRateWeekly
          firstAvailableUnitName
        }
      }
    }
  }
`;

const propTypes = {
  completeReviewCost: PropTypes.func.isRequired,
  receipt: PropTypes.shape({
    creditAmount: PropTypes.number.isRequired,
    feeAmount: PropTypes.number.isRequired,
    rentAmount: PropTypes.number.isRequired,
    rentMonth: PropTypes.string.isRequired,
    nextDueDate: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
  }),
  receiptLoading: PropTypes.bool.isRequired,
  isReservation: PropTypes.bool,
  submitLead: PropTypes.func.isRequired,
  updateTenant: PropTypes.func.isRequired,
  processMoveIn: PropTypes.func.isRequired,
  getLeadData: PropTypes.func.isRequired,
  leadLoading: PropTypes.bool.isRequired,
  leadData: PropTypes.shapeOf(),
  leadError: PropTypes.string,
  removeContact: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
  addContact: PropTypes.func.isRequired,
  checkPromoCode: PropTypes.func.isRequired,
  checkingPromoCode: PropTypes.bool.isRequired,
  checkPromoCodeData: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    discountPlanId: PropTypes.string,
  }),
  appliedPromotion: PropTypes.shape(),
};

const defaultProps = {
  receipt: undefined,
  isReservation: false,
  leadData: undefined,
  leadError: undefined,
  checkPromoCodeData: undefined,
  appliedPromotion: undefined,
};

const StorageMartCheckout = ({
  completeReviewCost,
  receipt,
  receiptLoading,
  isReservation,
  payload,
  isBuilderView,
  facilityData,
  getFirstAvailableUnitFromUnitGroup,
  findingAvailableUnit,
  availableUnitResponse,
  availableUnit,
  availableUnits,
  submitLead,
  updateTenant,
  processMoveIn,
  getLeadData,
  leadLoading,
  leadData,
  leadError,
  removeContact,
  updateContact,
  addContact,
  convertLeadToReservation,
  checkPromoCode,
  checkingPromoCode,
  checkPromoCodeData,
  appliedPromotion,
  removeAppliedPromotion,
}) => {
  const intl = useIntl();
  const [unitGroupId, setUnitGroupId] = useState();
  const [facilityId, setFacilityId] = useState();
  const {
    unitGroupId: queryUnitGroupId,
    facilityId: queryFacilityId,
    leadId: queryLeadId,
  } = getQueryParams();
  const [checkoutData, setCheckoutData] = useState();
  const [tenantData, setTenantData] = useState();
  const [currentStep, setCurrentStep] = useState(CHECKOUT_STEPS.CONTACT_INFORMATION);
  const [maxStep, setMaxStep] = useState(CHECKOUT_STEPS.CONTACT_INFORMATION);
  const [formError, setFormError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showMobileSummary, setShowMobileSummary] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorTokens.default);
  const [hasTrackedDataLayer, setHasTrackedDataLayer] = useState(false);
  const [currentLeadId, setCurrentLeadId] = useState();
  const [currentTenantId, setCurrentTenantId] = useState();
  const [currentUnitGroup, setCurrentUnitGroup] = useState();
  const continuingExistingLead = !!queryLeadId;

  const [facilityWithUnitGroupQuery, {
    loading: facilityWithUnitGroupLoading,
    error: facilityError,
    data: facilityWithUnitGroupData,
  }] = useLazyQuery(GET_FACILITY_WITH_UNIT_GROUP, {
    variables: { id: facilityId, unitGroupId },
  });

  const getApplicableDiscountPlanId = () => {
    const prioritizedDiscountPlans = sortBy(filter(currentUnitGroup?.discountPlans, ({ priority }) => !!priority), ['priority']);
    const availableDiscountPlanIds = map(prioritizedDiscountPlans, (
      discountPlan => discountPlan.id
    ));

    if (availableDiscountPlanIds.length) {
      return availableDiscountPlanIds[0];
    }

    return null;
  };

  const getInvoiceableItems = (unitId) => {
    const facilityLock = head(get(facilityData, 'facility.locks', []));
    const selectedUnit = find([...(availableUnits || []), availableUnit], unit => (
      unit.id === unitId
    ));
    const doorQuantityField = find(selectedUnit.custom_fields, customField => (
      customField.name.toLowerCase() === DOOR_QUANTITY_CUSTOM_FIELD.toLowerCase()
    ));

    if (facilityLock && doorQuantityField && parseInt(doorQuantityField.value, 10) > 0) {
      return [{ id: facilityLock.id, quantity: parseInt(doorQuantityField.value, 10) }];
    }

    return [];
  };

  const valueCoverageId = useMemo(
    () => {
      const facilityValueCoveragePlans = get(facilityData, 'facility.settings.valueCoveragePlans', []);
      const selectedPlan = find(facilityValueCoveragePlans, plan => (
        (plan.closetDefault && currentUnitGroup?.categoryName.toLowerCase() === 'closet')
        || (plan.default && currentUnitGroup?.categoryName.toLowerCase() !== 'closet')
      ));

      return selectedPlan?.storableId;
    },
    [facilityData, currentUnitGroup],
  );

  const onReviewCost = (date, unitId, leadId) => {
    const checkInDate = date.toLocaleDateString(DATE_FORMAT_LOCALE).replace(/\//g, '-');
    const discountPlanId = getApplicableDiscountPlanId();
    const invoiceableItems = getInvoiceableItems(unitId);
    const includeLeadId = leadData?.unit?.id === unitId;

    completeReviewCost(
      unitId,
      facilityId,
      0,
      checkInDate,
      [discountPlanId, appliedPromotion?.id],
      includeLeadId ? leadId : null,
      invoiceableItems,
      valueCoverageId,
    );
  };

  const onReturnToStep = previousStep => (
    setCurrentStep(previousStep)
  );

  // Set state values based on data returned from queries and query string
  useEffect(() => {
    if (!unitGroupId && queryUnitGroupId) {
      setUnitGroupId(queryUnitGroupId);
    }

    if (!unitGroupId && leadData?.unit?.unitGroupId) {
      setUnitGroupId(leadData.unit.unitGroupId);
    }

    if (!facilityId && queryFacilityId) {
      setFacilityId(queryFacilityId);
    }

    if (!currentUnitGroup && facilityWithUnitGroupData && get(facilityWithUnitGroupData, 'facility.unitGroup')) {
      setCurrentUnitGroup(get(facilityWithUnitGroupData, 'facility.unitGroup'));
    }

    if (leadData && leadData.desiredMoveInDate && availableUnit) {
      onReviewCost(new Date(`${leadData.desiredMoveInDate}T00:00:00`), availableUnit.id, leadData.leadId);
    }

    if (leadData && leadData.tenant) {
      setTenantData(omit(leadData.tenant, typeNameList));
    }
  }, [queryUnitGroupId, queryFacilityId, leadData, facilityWithUnitGroupData, availableUnit]);

  // Rerun the review cost when a promo code is applied or removed
  useEffect(() => {
    if (checkoutData) {
      onReviewCost(new Date(`${checkoutData.desiredMoveInDate}T00:00:00`), checkoutData.unit.id, checkoutData.leadId);
    }
  }, [appliedPromotion]);

  // Track the data layer events when facility and unit group data are available and the events
  // haven't been tracked yet
  useEffect(() => {
    if (!isBuilderView) {
      if (!hasTrackedDataLayer && facilityData && get(facilityData, 'facility') && currentUnitGroup) {
        setHasTrackedDataLayer(true);
        const facility = get(facilityData, 'facility');
        TRACK_BEGIN_CHECKOUT(currentUnitGroup, facility, intl);
        TRACK_VIEW_CART(currentUnitGroup, facility, intl);
      }
    }
  }, [facilityData, currentUnitGroup, hasTrackedDataLayer]);

  // Load initial unit group and lead data
  useEffect(() => {
    if (!isBuilderView) {
      if (unitGroupId && facilityId && !findingAvailableUnit) {
        getFirstAvailableUnitFromUnitGroup(unitGroupId, facilityId);
      }

      if (unitGroupId
        && facilityId
        && !facilityWithUnitGroupData
        && !facilityWithUnitGroupLoading
      ) {
        facilityWithUnitGroupQuery({ facilityId, unitGroupId });
      }

      if (queryLeadId && facilityId) {
        setCurrentLeadId(queryLeadId);
        getLeadData({ facilityId, leadId: queryLeadId });
      }
    }
  }, [unitGroupId, facilityId, queryLeadId]);

  if (!isBuilderView && (leadError || facilityError)) {
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Alert className="mt-5" bsStyle="danger">
              <Translation id="widgets.checkout.abandonCart.error" defaultMessage="An error was encountered when loading the checkout information" />
            </Alert>
          </Col>
        </Row>
      </Grid>
    );
  }

  if (!isBuilderView && leadData && (leadData.closed || !leadData.success)) {
    let messageStyle = 'danger';
    let message = <Translation id="widgets.checkout.abandonCart.unavailable" defaultMessage="The checkout you are trying to load is no longer available" />;

    if (leadData.expired) {
      message = <Translation id="widgets.checkout.abandonCart.expired" defaultMessage="We're sorry, your storage unit reservation has expired. Please select a new unit." />;
    } else if (!leadData.movedIn) {
      message = <Translation id="widgets.checkout.abandonCart.closed" defaultMessage="We're sorry, this storage unit is no longer available. Please select a new unit." />;
    } else if (leadData.movedIn) {
      message = <Translation id="widgets.checkout.abandonCart.movedIn" defaultMessage="Success! Rental complete." />;
      messageStyle = 'success';
    }

    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Alert className="mt-5" bsStyle={messageStyle}>
              {message}
            </Alert>
          </Col>
          <Col md={3} sm={6} xs={12}>
            <SMFacilityLinkButton
              facility={facilityData.facility}
              id="widgets.movein.unitUnavailable.backToFacilityPage"
              defaultMessage="Return to facility page"
            />
          </Col>
        </Row>
      </Grid>
    );
  }

  if (!isBuilderView && (findingAvailableUnit || leadLoading)) {
    return (
      <LoadingSkeleton />
    );
  }

  return (
    <ApolloClientConsumer>
      {() => {
        const onStepSubmit = async (values, thisStep, nextStep, scrollToRef) => {
          setFormError(false);
          setErrorMessage(errorTokens.default);
          setSubmitting(true);

          if (thisStep === CHECKOUT_STEPS.CONTACT_INFORMATION
            || thisStep === CHECKOUT_STEPS.UNIT_INFORMATION
          ) {
            const desiredMoveInDate = (values.moveInDate && values.moveInDate.toLocaleDateString(DATE_FORMAT_LOCALE).replace(/\//g, '-')) || get(checkoutData || leadData, 'desiredMoveInDate');
            const leadParams = {
              leadId: currentLeadId,
              facilityId,
              unitGroupId,
              unitId: values.unitId || checkoutData?.unit?.id || availableUnit?.id,
              source: 'Online Rental in Progress',
              moveInDate: desiredMoveInDate,
              tenantId: currentTenantId,
              discountPlanId: getApplicableDiscountPlanId(desiredMoveInDate),
              ...adCampaignValues(),
            };

            if (thisStep === CHECKOUT_STEPS.CONTACT_INFORMATION) {
              leadParams.tenant = {
                id: currentTenantId,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phoneNumberId: get(checkoutData, 'tenant.phoneNumberId'),
                phone: get(values, 'phone.unformattedPhoneNumber'),
                smsOptIn: values.smsOptIn,
                addressId: get(checkoutData, 'tenant.addressId'),
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                state: values.state,
                postal: values.postal,
                country: values.country,
              };
            }

            submitLead(leadParams).then((data) => {
              setSubmitting(false);

              if (data.data.lead) {
                if (nextStep > maxStep) {
                  setMaxStep(nextStep);
                }

                setCurrentLeadId(data.data.lead.leadId);
                setCurrentTenantId(data.data.lead.tenant.id);
                setCurrentStep(nextStep);
                setCheckoutData(omit(data.data.lead, typeNameList));
                scrollToRef.current?.scrollIntoView({ behavior: 'smooth' }); // eslint-disable-line no-unused-expressions
              } else {
                setErrorMessage('An error was encountered');
                setFormError(true);
              }
            }).catch(() => {
              setFormError(true);
              setSubmitting(false);
            });
          } else if (thisStep === CHECKOUT_STEPS.ACCOUNT_DETAILS) {
            let modifyContactError = false;
            const emergencyContact = find(tenantData?.contacts, { description: 'Emergency Contact' });
            const authorizedUser = find(tenantData?.contacts, { description: 'Authorized User' });

            const tenantParams = {
              facilityId,
              tenantId: currentTenantId,
              tenant: {
                isMilitary: values.activeDutyMilitary === 'yes',
                commanderFirstName: values.commanderFirstName,
                commanderLastName: values.commanderLastName,
                unitAddress: values.unitAddress,
                unitCity: values.unitCity,
                unitState: values.unitState,
                unitPostal: values.unitPostal,
                contacts: [],
              },
            };

            if (values.addEmergencyContact === 'yes') {
              const emergencyContactParams = {
                firstName: values.emergencyContactFirstName,
                lastName: values.emergencyContactLastName,
                phoneNumber: values.emergencyContactPhone.unformattedPhoneNumber,
                primary: true,
                description: 'Emergency Contact',
              };

              if (emergencyContact) {
                const updateContactResponse = await updateContact({
                  facilityId,
                  tenantId: currentTenantId,
                  contactId: emergencyContact.id,
                  contact: emergencyContactParams,
                });

                if (!updateContactResponse.data.updateContact.success) {
                  setErrorMessage('An error was encountered when trying to update a contact');
                  setFormError(true);
                  modifyContactError = true;
                }
              } else {
                const addContactResponse = await addContact({
                  facilityId,
                  tenantId: currentTenantId,
                  contact: emergencyContactParams,
                });

                if (!addContactResponse.data.addContact.success) {
                  setErrorMessage('An error was encountered when trying to add a contact');
                  setFormError(true);
                  modifyContactError = true;
                }
              }
            } else if (emergencyContact) {
              const removeContactResponse = await removeContact({
                facilityId,
                tenantId: currentTenantId,
                contactId: emergencyContact.id,
              });

              if (!removeContactResponse.data.removeContact.success) {
                setErrorMessage('An error was encountered when trying to remove a contact');
                setFormError(true);
                modifyContactError = true;
              }
            }

            if (values.addContact === 'yes') {
              const authorizedUserParams = {
                firstName: values.contactFirstName,
                lastName: values.contactLastName,
                primary: false,
                description: 'Authorized User',
              };

              if (authorizedUser) {
                const updateContactResponse = await updateContact({
                  facilityId,
                  tenantId: currentTenantId,
                  contactId: authorizedUser.id,
                  contact: authorizedUserParams,
                });

                if (!updateContactResponse.data.updateContact.success) {
                  setErrorMessage('An error was encountered when trying to update a contact');
                  setFormError(true);
                  modifyContactError = true;
                }
              } else {
                const addContactResponse = await addContact({
                  facilityId,
                  tenantId: currentTenantId,
                  contact: authorizedUserParams,
                });

                if (!addContactResponse.data.addContact.success) {
                  setErrorMessage('An error was encountered when trying to add a contact');
                  setFormError(true);
                  modifyContactError = true;
                }
              }
            } else if (authorizedUser) {
              const removeContactResponse = await removeContact({
                facilityId,
                tenantId: currentTenantId,
                contactId: authorizedUser.id,
              });

              if (!removeContactResponse.data.removeContact.success) {
                setErrorMessage('An error was encountered when trying to remove a contact');
                setFormError(true);
                modifyContactError = true;
              }
            }

            if (!modifyContactError) {
              updateTenant(tenantParams).then((data) => {
                setSubmitting(false);

                if (data.data.updateTenant.success) {
                  if (nextStep > maxStep) {
                    setMaxStep(nextStep);
                  }

                  setCurrentStep(nextStep);
                  setTenantData(omit(data.data.updateTenant.tenant, typeNameList));
                  scrollToRef.current?.scrollIntoView({ behavior: 'smooth' }); // eslint-disable-line no-unused-expressions
                } else {
                  setErrorMessage('An error was encountered');
                  setFormError(true);
                }
              }).catch(() => {
                setFormError(true);
                setSubmitting(false);
              });
            }
          } else if (thisStep === CHECKOUT_STEPS.PAYMENT) {
            if (values.payOption === 'payNow') {
              const moveInParams = {
                facilityId,
                tenantId: currentTenantId,
                leadId: currentLeadId,
                unitId: checkoutData.unit.id,
                moveInDate: checkoutData.desiredMoveInDate,
                paymentMethod: {
                  cardNumber: values.creditCardNumber,
                  expirationDate: `${padStart(values.creditCardExpireMonth, 2, '0')}/${values.creditCardExpireYear}`,
                  securityCode: values.creditCardSecurityCode,
                  reuseable: true,
                  autopayEnabled: values.autopayEnroll,
                  autopayDay: 1,
                },
                invoiceableItems: getInvoiceableItems(checkoutData.unit.id),
                insuranceId: valueCoverageId,
                discountPlanIds: [appliedPromotion?.id].filter(Boolean),
              };

              processMoveIn(moveInParams).then((data) => {
                if (data.data.storableTenantMoveIn.success) {
                  trackDataLayerValue({
                    event: 'fmi_success',
                    category_rin: 'Success',
                    action_rin: 'FMI',
                    brand: getBrandCode(get(facilityData, 'facility.brandName')),
                    storeId: get(facilityData, 'facility.storeNumber'),
                  });

                  const queryString = `facilityId=${facilityId}&confirmationId=${data.data.storableTenantMoveIn.moveInUnitEventId}`;
                  window.location = `${intlPath(intl.locale, payload.checkoutConfirmationPath)}?${queryString}`;
                } else {
                  setErrorMessage('An error was encountered');
                  setFormError(true);
                  setSubmitting(false);
                }
              }).catch(() => {
                setFormError(true);
                setSubmitting(false);
              });
            } else {
              convertLeadToReservation({
                facilityId,
                leadId: currentLeadId,
                unitId: checkoutData.unit.id,
                moveInDate: new Date().toLocaleDateString(DATE_FORMAT_LOCALE).replace(/\//g, '-'),
                originalMoveInDate: checkoutData.desiredMoveInDate,
                discountPlanIds: [appliedPromotion?.id].filter(Boolean),
              }).then((data) => {
                if (data.data.lead.success) {
                  trackDataLayerValue({
                    event: 'res_success',
                    category_rin: 'Success',
                    action_rin: 'RES',
                    brand: getBrandCode(get(facilityData, 'facility.brandName')),
                    storeId: get(facilityData, 'facility.storeNumber'),
                  });

                  const queryString = `facilityId=${facilityId}&leadId=${data.data.lead.leadId}`;
                  window.location = `${intlPath(intl.locale, payload.reservationConfirmationPath)}?${queryString}`;
                } else {
                  setErrorMessage('An error was encountered');
                  setFormError(true);
                  setSubmitting(false);
                }
              }).catch(() => {
                setFormError(true);
                setSubmitting(false);
              });
            }
          }
        };

        return (
          <CurrentStepContext.Provider value={
            {
              currentStep,
              maxStep,
              onStepSubmit,
              formError,
              submitting,
              checkoutData: checkoutData || leadData,
              leadData,
              onReturnToStep,
              isReservation,
              errorMessage,
              availableUnit,
              availableUnits,
            }
          }
          >
            <div>
              <CheckoutProgress isReservation={isReservation} />
              <Grid>
                <Row>
                  <Col xs={12}>
                    <div className={classNames('row', styles.flex)}>
                      <Col xs={12} mdPush={8} md={4}>
                        <OrderSummary
                          facility={get(facilityWithUnitGroupData, 'facility')}
                          showMobileSummary={showMobileSummary}
                          setShowMobileSummary={setShowMobileSummary}
                          receipt={receipt}
                          receiptLoading={receiptLoading}
                          discountPlan={get(currentUnitGroup, 'discountPlans[0]')}
                          availableUnit={availableUnit}
                        />
                      </Col>
                      <Col xs={12} mdPull={4} md={7}>
                        <MobileOrderSummary
                          setShowMobileSummary={setShowMobileSummary}
                          facility={get(facilityWithUnitGroupData, 'facility')}
                          receipt={receipt}
                        />
                        {!isBuilderView
                          && availableUnitResponse
                          && !availableUnitResponse.success ? (
                          <Alert bsStyle="danger">{availableUnitResponse.error}</Alert>) : null}
                        {currentUnitGroup && availableUnit && (
                          <>
                            <ContactInformation
                              facility={get(facilityWithUnitGroupData, 'facility')}
                            />
                            <UnitInformation
                              facility={get(facilityWithUnitGroupData, 'facility')}
                              onReviewCost={onReviewCost}
                              appliedDiscountPlanId={getApplicableDiscountPlanId()}
                              continuingExistingLead={continuingExistingLead}
                              availableUnit={availableUnit}
                            />
                            {!isReservation && (
                              <>
                                <AccountDetails
                                  facility={get(facilityWithUnitGroupData, 'facility')}
                                  tenantData={tenantData}
                                />
                                <Payment
                                  facility={get(facilityWithUnitGroupData, 'facility')}
                                  receipt={receipt}
                                  checkPromoCode={checkPromoCode}
                                  checkingPromoCode={checkingPromoCode}
                                  checkPromoCodeData={checkPromoCodeData}
                                  appliedPromotion={appliedPromotion}
                                  removeAppliedPromotion={removeAppliedPromotion}
                                />
                              </>
                            )}
                          </>
                        )}
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Grid>
            </div>
          </CurrentStepContext.Provider>
        );
      }}
    </ApolloClientConsumer>
  );
};

StorageMartCheckout.propTypes = propTypes;
StorageMartCheckout.defaultProps = defaultProps;

export default StorageMartCheckout;
