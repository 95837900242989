import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { object, string, bool } from 'yup';
import get from 'lodash/get';
import { Row, Col, Alert } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Translation from '../../../../../components/stateless/Translation';
import withForm from '../../../../../../admin/hocs/withForm';
import SMFormFieldToggle from '../../../../components/stateless/SMFormFieldToggle';
import CheckoutSubmitButton from '../CheckoutSubmitButton';
import CheckoutFormFieldText from '../CheckoutFormFieldText';
import CheckoutFormFieldCreditCard from '../CheckoutFormFieldCreditCard';
import CheckoutFormExpirationDate from '../CheckoutFormExpirationDate';
import {
  TRACK_CLICK_CHECKOUT_STEP_FIVE_SUBMIT,
  TRACK_CHECKOUT_ADD_PAYMENT_INFO,
  TRACK_CHECKOUT_STEP,
} from '../../../../../../src/web/utils/dataLayer';
import hourglass from './hourglass.svg';
import circleCheckmark from './circle-checkmark.svg';
import styles from './styles.scss';
import SMTooltip from '../../../../components/stateless/SMTooltip';
import {
  validateCreditCardNumber,
  validateCreditCardSecurityCode,
  validateExpireDate,
} from '../../StorageMartCheckoutTotalRecall/Payment/validation';

const propTypes = {
  formError: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  facility: PropTypes.shape().isRequired,
  errorMessage: PropTypes.shape().isRequired,
  receipt: PropTypes.shape({
    totalAmount: PropTypes.number.isRequired,
  }),
  checkPromoCode: PropTypes.func().isRequired,
  checkingPromoCode: PropTypes.bool.isRequired,
  checkPromoCodeData: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    discountPlanId: PropTypes.string,
  }),
  appliedPromotion: PropTypes.shape(),
};

const defaultProps = {
  receipt: undefined,
  checkPromoCodeData: undefined,
  appliedPromotion: undefined,
};

const validationSchema = object().shape({
  creditCardNumber: string().nullable()
    .when('payOption', {
      is: 'payNow',
      then: string().required('widgets.checkout.creditCardNumber.required'),
    }),
  creditCardExpireMonth: string().nullable()
    .when('payOption', {
      is: 'payNow',
      then: string().required('widgets.checkout.creditCardExpireMonth.required'),
    }),
  creditCardExpireYear: string().nullable()
    .when('payOption', {
      is: 'payNow',
      then: string().required('widgets.checkout.creditCardExpireYear.required'),
    }),
  creditCardSecurityCode: string().nullable()
    .when('payOption', {
      is: 'payNow',
      then: string().required('widgets.checkout.creditCardSecurityCode.required'),
    }),
  autopayEnroll: bool(),
  payOption: string()
    .required('widgets.checkout.payOption.required'),
});

const initialValues = {
  creditCardNumber: '',
  creditCardExpireMonth: '',
  creditCardExpireYear: '',
  creditCardSecurityCode: '',
  autopayEnroll: false,
  payOption: 'payNow',
};

const today = new Date();

const FORM_SUBMIT_TYPES = {
  payment: 'payment',
  promo: 'promo',
};

const PaymentForm = ({
  submitForm,
  submitting,
  formError,
  values,
  facility,
  receipt,
  leadStatus,
  setFieldValue,
  checkPromoCode,
  checkingPromoCode,
  checkPromoCodeData,
  appliedPromotion,
  removeAppliedPromotion,
}) => {
  const intl = useIntl();
  const [hasTrackedDataLayer, setHasTrackedDataLayer] = useState(false);
  const [showPromoCode, setShowPromoCode] = useState(!!appliedPromotion);

  useEffect(() => {
    if (!hasTrackedDataLayer && facility) {
      setHasTrackedDataLayer(true);
      TRACK_CHECKOUT_STEP(
        get(facility, 'unitGroup'),
        facility,
        'new_booking_flow_step4',
        'payment',
      );
    }
  }, [facility, hasTrackedDataLayer]);

  const payNowClick = rentalType => (
    TRACK_CHECKOUT_ADD_PAYMENT_INFO(
      get(facility, 'unitGroup'),
      facility,
      get(receipt, 'totalAmount'),
      intl,
      rentalType,
    )
  );

  const tooltipCCVTitle = `widgets.checkout.ccvTooltipBody.${values.creditCardNumber.startsWith('3') ? 'amex' : 'default'}`;
  const tooltipCCVTitleText = `Your security code is a ${values.creditCardNumber.startsWith('3')
    ? 'four digit number above the credit card number'
    : 'three digit number on the back of your card'}.`;

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formValues = Object.fromEntries(formData.entries());

    if (event.nativeEvent.submitter.value === FORM_SUBMIT_TYPES.payment) {
      submitForm();
    } else if (event.nativeEvent.submitter.value === FORM_SUBMIT_TYPES.promo) {
      checkPromoCode(
        facility.id,
        facility.unitGroup.id,
        formValues.promoCode,
      );
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      {
        formError && (
          <Alert bsStyle="danger">
            <Translation
              id="widgets.checkout.formError"
              defaultMessage="An error was encountered when submitting the form. Please try again."
            />
          </Alert>
        )
      }
      {
        leadStatus !== 'reserved' ? (
          <>
            <Row>
              <Col xs={12} md={8}>
                <h5>
                  <Translation
                    id="widgets.checkout.payOption"
                    defaultMessage="When would you like to pay?"
                  />
                </h5>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={3}>
                <SMFormFieldToggle
                  type="radio"
                  name="payOption"
                  value="payNow"
                  label={(
                    <span className={styles.formFieldToggleLabel}>
                      <Translation
                        id="widgets.checkout.payNow"
                        defaultMessage="Pay Online"
                      />
                    </span>
                  )}
                />
              </Col>
              <Col xs={6} md={4}>
                <SMFormFieldToggle
                  type="radio"
                  name="payOption"
                  value="payLater"
                  label={(
                    <span className={styles.formFieldToggleLabel}>
                      <Translation
                        id="widgets.checkout.payLater"
                        defaultMessage="Pay in Store Later"
                      />
                    </span>
                  )}
                />
              </Col>
            </Row>
          </>
        ) : null
      }
      {values.payOption === 'payLater' && (
        <div className="mb-5">
          <Translation
            id="widgets.checkout.storable.payLaterInfo"
            defaultMessage="Please come in to the office within 72 hours to make your payment and finalize your rental. We'll call you before your check-in date to make sure everything is ready. We accept cash, credit card, debit, or check."
          />
        </div>
      )}
      <Row>
        <Col xs={12} md={7}>
          {!showPromoCode ? (
            <div className="mb-5">
              <button className="underline pl-0" onClick={() => setShowPromoCode(true)} type="button">
                <Translation
                  id="widgets.checkout.applyPromo.havePromoButton"
                  defaultMessage="+ Have Promo Code?"
                />
              </button>
            </div>
          ) : null}
          {showPromoCode && !appliedPromotion ? (
            <div className="mb-5">
              {checkPromoCodeData && !checkPromoCodeData?.success ? (
                <Alert bsStyle="danger">
                  <Translation
                    id="widgets.checkout.applyPromo.error"
                    defaultMessage="The promo is not valid or no longer available"
                  />
                </Alert>
              ) : null}
              <div className="flex flex-row gap-3 items-center">
                <CheckoutFormFieldText
                  name="promoCode"
                  label={{
                    defaultMessage: 'Add Promo Code',
                    id: 'widgets.checkout.applyPromo.promoCodeLabel',
                  }}
                />
                <CheckoutSubmitButton
                  submitLabel={{
                    id: 'widgets.checkout.applyPromo.submitButton.submit',
                    defaultMessage: 'Apply',
                  }}
                  submitting={submitting || checkingPromoCode}
                  submittingLabel={{
                    id: 'widgets.checkout.applyPromo.submitButton.submitting',
                    defaultMessage: 'Applying...',
                  }}
                  name="submitType"
                  value={FORM_SUBMIT_TYPES.promo}
                />
              </div>
            </div>
          ) : null}
          {showPromoCode && appliedPromotion ? (
            <div className="mb-5">
              <div className="flex flex-row gap-2">
                <div>
                  <img src={circleCheckmark} className="size-[20px]" alt="Promo applied" />
                </div>
                <div>
                  <div>
                    {appliedPromotion.name}
                    <Translation
                      id="widgets.checkout.applyPromo.promoLabel"
                      defaultMessage=" - Promo Code"
                    />
                  </div>
                  <button className="underline pl-0" onClick={removeAppliedPromotion} type="button">
                    <Translation
                      id="widgets.checkout.applyPromo.removeButton"
                      defaultMessage="Remove"
                    />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </Col>
      </Row>
      {values.payOption === 'payNow' && (
        <>
          <Row className="border-t">
            <Col xs={12} md={8}>
              <CheckoutFormFieldCreditCard
                requiredStar
                name="creditCardNumber"
                type="tel"
                label={{
                  defaultMessage: 'Credit Card Number',
                  id: 'widgets.checkout.creditCardNumber',
                }}
                autoComplete="cc-number"
                validate={value => validateCreditCardNumber(value)}
                autoCorrect="off"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4}>
              <CheckoutFormExpirationDate
                setFieldValue={setFieldValue}
                validate={() => (
                  validateExpireDate(
                    values.creditCardExpireMonth,
                    values.creditCardExpireYear,
                    today,
                  )
                )}
              />
            </Col>
            <Col xs={6} md={3}>
              <div style={{ position: 'relative' }}>
                <CheckoutFormFieldText
                  requiredStar
                  name="creditCardSecurityCode"
                  style={{ paddingRight: '44px' }}
                  type="tel"
                  maxLength={4}
                  label={{
                    defaultMessage: 'Security Code',
                    id: 'widgets.checkout.creditCardSecurityCode',
                  }}
                  autoComplete="cc-csc"
                  autoCorrect="off"
                  validate={value => validateCreditCardSecurityCode(value)}
                />
                <div
                  style={{
                    position: 'absolute',
                    right: '2px',
                    padding: '12px',
                  }}
                  className={styles.tooltipCCV}
                >
                  <SMTooltip
                    title={(
                      <Translation
                        id="widgets.checkout.ccvTooltipTitle"
                        defaultMessage="Locate Your Security Code"
                      />
                    )}
                    body={(
                      <Translation
                        id={tooltipCCVTitle}
                        defaultMessage={tooltipCCVTitleText}
                      />
                    )}
                    className={`${styles.tooltip}`}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SMFormFieldToggle
                type="checkbox"
                name="autopayEnroll"
                value
                label={(
                  <Translation
                    id="widgets.checkout.autopayEnroll"
                    defaultMessage="Enroll in autopay program to charge my card on the 1st of every month"
                  />
                )}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs={12}>
          {submitting && <img src={hourglass} className={styles.hourglass} alt="Payment loading" />}
          {values.payOption === 'payLater' && (
            <CheckoutSubmitButton
              submitLabel={{
                id: 'widgets.checkout.payButton.submit.payLater',
                defaultMessage: 'Pay Later and Continue',
              }}
              submitting={submitting}
              submittingLabel={{
                id: 'widgets.checkout.payButton.submitting.payLater',
                defaultMessage: 'Continuing...',
              }}
              disabled={checkingPromoCode}
              onClick={() => payNowClick('RES')}
              {...TRACK_CLICK_CHECKOUT_STEP_FIVE_SUBMIT}
              name="submitType"
              value={FORM_SUBMIT_TYPES.payment}
            />
          )}
          {values.payOption === 'payNow' && (
            <CheckoutSubmitButton
              submitLabel={{
                id: 'widgets.checkout.payButton.submit',
                defaultMessage: 'Pay Now and Continue',
              }}
              submitting={submitting}
              submittingLabel={{
                id: 'widgets.checkout.payButton.submitting',
                defaultMessage: 'Paying...',
              }}
              disabled={checkingPromoCode}
              onClick={() => payNowClick('FMI')}
              {...TRACK_CLICK_CHECKOUT_STEP_FIVE_SUBMIT}
              name="submitType"
              value={FORM_SUBMIT_TYPES.payment}
            />
          )}
        </Col>
      </Row>
    </form>
  );
};

PaymentForm.propTypes = propTypes;
PaymentForm.defaultProps = defaultProps;

export default withForm({
  mapPropsToValues: () => {
    const formattedInitialValues = initialValues;

    return formattedInitialValues;
  },
  validationSchema,
  handleSubmit: (formPayload, formikBag) => (
    formikBag.props.onSubmit(
      formPayload,
      formikBag.props.currentStep,
      formikBag.props.nextStep,
      formikBag.props.scrollToRef,
    )
  ),
})(PaymentForm);
